import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import { Divider } from 'rsuite';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
const SavedSearchList = () => {
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
  const [savedSearches, setSavedSearches] = useState([]);
  const [token, setToken] = useState('');
  const [idForDelete, setIdForDelete] = useState('');
  const [ajaxInActionDelete, setAjaxInActionDelete] = useState(false);
  const [ajaxInActionLoad, setAjaxInActionLoad] = useState(false);

  const handleDelete = async (e, id, keywords) => {
    const notify = (text, error = false) => {
      if (!error) {
        toast.success(`Saved Search - ${text} was deleted`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warn(`Error deleting saved search - ${text}. Please try again`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
    }
    e.preventDefault();
    if (!ajaxInActionDelete) {
      setAjaxInActionDelete(true);
      setIdForDelete(id);
      try {
        const response = await fetch(`https://pvpiwtdvcm.us-east-1.awsapprunner.com/savedsearch/remove/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'DELETE'
        })
        const r = await response.json();
        const response2 = await fetch(`https://pvpiwtdvcm.us-east-1.awsapprunner.com/savedsearch`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const r2 = await response2.json();
        notify(keywords);
        setSavedSearches(r2?.body?.Item?.searches);
        setAjaxInActionDelete(false);
        setIdForDelete('');
      } catch (e) {
        notify(keywords, true)
        setAjaxInActionDelete(false);
        setIdForDelete('');
      }
    }
  }
  useEffect(() => {
    const getSavedSearches = async () => {
      try {
        setAjaxInActionLoad(true);
        const token = await getAccessTokenSilently({
          audience: 'https://hobby-api',
          scope: 'read:savedsearches'
        });
        setToken(token);
        const response = await fetch(`https://pvpiwtdvcm.us-east-1.awsapprunner.com/savedsearch`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const r = await response.json();
        setSavedSearches(r?.body?.Item?.searches);
        setAjaxInActionLoad(false);
      } catch (e) {
        console.error(e);
        setAjaxInActionLoad(false);
      }
    };
    getSavedSearches();
  }, [getAccessTokenSilently, user?.sub]);

  if (isLoading) {
    return <div>Loading...</div>
  }

  const getSort = (s) => {
    if (s === 'PriceAsc') {
      return 'Price Ascending';
    } else if (s === 'PriceDesc') {
      return 'Price Descending';
    } else if (s === 'EndingSoonest') {
      return 'Ending Soonest';
    } else {
      return 'Newly Created';
    }
  }

  const priceFilter = (priceLow, priceHigh) => {
    if (priceLow && priceHigh && priceLow > 0 && priceHigh > 0) {
      return `$${numeral(priceLow).format('0,0')} - $${numeral(priceHigh).format('0,0')}`
    } else if (priceLow && priceLow > 0) {
      return `From $${numeral(priceLow).format('0,0')}`
    } else if (priceHigh && priceHigh > 0) {
      return `Under $${numeral(priceHigh).format('0,0')}`
    } else {
      return '';
    }
  }
  const getPriceExplanation = (priceLow, priceHigh) => {
    const result = priceFilter(priceLow, priceHigh);
    return result.length === 0 ? 'Any' : result;
  }
  return (
    <>
      {(isAuthenticated && savedSearches?.length > 0) ? <>
        <Divider className="text-gold" />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <ul>
          {savedSearches.map(function (search, index) {
            return (
              <li key={index}>

                <h5 className="text-gold">
                  <Link to={`?${search.url}`} className="savedsearchlink">{search?.keywords} </Link><FontAwesomeIcon icon={faBan} onClick={e => handleDelete(e, search?.id, search?.keywords)} />
                  {(ajaxInActionDelete && search?.id === idForDelete) ? <PulseLoader color="#edc773" size={16} loading /> : null}
                </h5>
                {search?.body?.datasources?.length === 7 && <p className="savedsearch">Sites: all</p>}
                {search?.body?.datasources?.length !== 7 && <p className="savedsearch">Sites: {search?.body?.datasources.join(', ')}</p>}
                {search?.body?.type === 'ALL' && <p className="savedsearch">Type: Auctions & Fixed Prices</p>}
                {search?.body?.type === 'AUCTION' && <p className="savedsearch">Type: Only Auctions</p>}
                {search?.body?.type === 'FIXEDPRICE' && <p className="savedsearch">Type: Only Fixed Price/BIN</p>}
                {<p className="savedsearch">Sort: {getSort(search.body.sort)}</p>}
                {<p className="savedsearch">Price: {getPriceExplanation(search.body.priceLow, search.body.priceHigh)}</p>}

                <Divider />
              </li>)
          })}
        </ul>
      </>
        : ajaxInActionLoad ? <PulseLoader color="#edc773" size={15} loading /> :
          <p className="text-gold">As a registered user you can now save your searches! Add up to five saved searches
            to watch and they will show up here.
          </p>
      }
    </>
  )

}
export default SavedSearchList;