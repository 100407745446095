import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import { FaSlidersH } from 'react-icons/fa';
import '../styles/checkbox.css';

const CustomCheckbox = ({ checked, onChange, label }) => (
    <div className="custom-checkbox">
        <input
            type="checkbox"
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            className="checkbox-input"
        />
        <span className="checkbox-label">{label}</span>
    </div>
);

const Main = ({ setQuery }) => {
    const [localQuery, setLocalQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [fixed, setFixed] = useState(true);
    const [auction, setAuction] = useState(true);
    const [asc, setAsc] = useState(true);
    const [desc, setDesc] = useState(false);
    const [comc, setComc] = useState(true);
    const [ebay, setEbay] = useState(true);
    const [myslabs, setMyslabs] = useState(true);
    const [pwcc, setPwcc] = useState(true);
    const [fan, setFan] = useState(true);
    const [arena, setArena] = useState(true);
    const [endingSoonest, setEndingSoonest] = useState(false);
    const [newlyCreated, setNewlyCreated] = useState(false);

    const submitSearch = (e) => {
        e.preventDefault();
        if(open) {
            const sites = getSitesToSearchInternal();
            const sorts = getSorting();
            const types = getType();
            setQuery(localQuery, true, sites, sorts, types);
        } else {
            setQuery(localQuery, false);
        }
    }

    const getSitesToSearchInternal = () => {
        const s = [];
        if (comc) {
            s.push('comc')
        }
        if (ebay) {
            s.push('ebay')
        }
        if (myslabs) {
            s.push('myslabs');
        }
        if (pwcc) {
            s.push('pwcc');
        }
        if (fan) {
           s.push('fan');
        }
        if (arena) {
            s.push('arena');
        }
        return s
    }

    const getSorting = () =>{
        if (asc) {
            return 'PriceAsc';
        } else if (desc) {
            return 'PriceDesc';
        } else if (endingSoonest) {
            return 'EndingSoonest';
        } else {
            return 'NewlyCreated';
        }
    }

    const getType = () => {
        if (fixed && auction) {
            return 'ALL'
        } else if (auction) {
            return 'AUCTION';
        } else {
            return 'FIXEDPRICE';
        }
    }

    const getFilterSummary = () => {
        const sites = getSitesToSearchInternal();
        const type = getType();
        const sort = getSorting();
        
        const siteSummary = sites.length === 6 ? "all sites" : sites.join(", ");
        const typeSummary = type === "ALL" ? "all types" : type.toLowerCase();
        const sortSummary = sort === "PriceAsc" ? "price (low to high)" :
                          sort === "PriceDesc" ? "price (high to low)" :
                          sort === "EndingSoonest" ? "ending soonest" :
                          "newly created";

        return {
            summary: `Searching ${siteSummary} | ${typeSummary} | sorted by ${sortSummary}`,
            hint: "click filter to modify"
        };
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFilterClick = () => {
        setOpen(!open);
    };

    return (<>
        <div className="caro_header">
            <div className="wrapper">
                <div className="container">
                    <div className="search-container">
                        <Form className="d-flex" onSubmit={submitSearch}>
                            <div className="search-input-wrapper">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Player, grade..." 
                                    size="md" 
                                    className="search-input" 
                                    value={localQuery} 
                                    onInput={e => setLocalQuery(e.target.value)} 
                                />
                                <Button 
                                    variant="outline-success" 
                                    type="button"
                                    className="filter-button"
                                    onClick={handleFilterClick}
                                >
                                    <FaSlidersH className={open ? 'active' : ''} />
                                </Button>
                            </div>
                            <Button variant="outline-success" type="submit" className="search-button">Search</Button>
                        </Form>
                        <div className="filter-summary" style={{ display: localQuery ? 'block' : 'none' }}>
                            <div className="summary-text">{getFilterSummary().summary}</div>
                            <div className="summary-hint">{getFilterSummary().hint}</div>
                        </div>
                    </div>
                    <div className={`advanced-search-container ${open ? 'show' : ''}`}>
                        <button 
                            type="button"
                            className="close-filter-button"
                            onClick={handleClose}
                            aria-label="Close filters"
                        >
                            ×
                        </button>
                        <div className="row">
                            <div className="col-md">
                                <p className="filter-section-title">Sites</p>
                                <div className="checkbox-grid">
                                    <CustomCheckbox checked={comc} onChange={setComc} label="Comc" />
                                    <CustomCheckbox checked={ebay} onChange={setEbay} label="Ebay" />
                                    <CustomCheckbox checked={myslabs} onChange={setMyslabs} label="MySlabs" />
                                    <CustomCheckbox checked={pwcc} onChange={setPwcc} label="PWCC" />
                                    <CustomCheckbox checked={fan} onChange={setFan} label="Fanatics" />
                                    <CustomCheckbox checked={arena} onChange={setArena} label="ArenaClub" />
                                </div>
                            </div>
                            <div className="col-md">
                                <p className="filter-section-title">Type</p>
                                <div className="checkbox-grid">
                                    <CustomCheckbox checked={auction} onChange={setAuction} label="Auctions" />
                                    <CustomCheckbox checked={fixed} onChange={setFixed} label="FixedPrice" />
                                </div>
                            </div>
                            <div className="col-md">
                                <p className="filter-section-title">Sort</p>
                                <div className="checkbox-grid">
                                    <CustomCheckbox 
                                        checked={asc} 
                                        onChange={(checked) => {
                                            setAsc(checked);
                                            setDesc(!checked);
                                            setEndingSoonest(!checked);
                                            setNewlyCreated(!checked);
                                        }} 
                                        label="Low to High" 
                                    />
                                    <CustomCheckbox 
                                        checked={desc} 
                                        onChange={(checked) => {
                                            setDesc(checked);
                                            setAsc(!checked);
                                            setEndingSoonest(!checked);
                                            setNewlyCreated(!checked);
                                        }} 
                                        label="High to Low" 
                                    />
                                    <CustomCheckbox 
                                        checked={endingSoonest} 
                                        onChange={(checked) => {
                                            setEndingSoonest(checked);
                                            setDesc(!checked);
                                            setAsc(!checked);
                                            setNewlyCreated(!checked);
                                        }} 
                                        label="Ending Soonest" 
                                    />
                                    <CustomCheckbox 
                                        checked={newlyCreated} 
                                        onChange={(checked) => {
                                            setNewlyCreated(checked);
                                            setEndingSoonest(!checked);
                                            setDesc(!checked);
                                            setAsc(!checked);
                                        }} 
                                        label="Newly Created" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container container-custom margin_80_0">
            <div className="main_title_2">
                <span><em></em></span>
                <br />
                <p className="terms2">When you click on links to various merchants on this site and make a purchase, this can result in this site earning a commission. Affiliate programs and affiliations include, but are not limited to, the eBay Partner Network.</p>
            </div>
        </div>
    </>
    )
}

export default Main;
