import {useQuery} from '@tanstack/react-query'

const DOMAIN = `https://pvpiwtdvcm.us-east-1.awsapprunner.com`



const isEmptyResultsObject = (r) => {
    const totallyEmptyObject = r && Object.keys(r).length === 0 &&
        Object.getPrototypeOf(r) === Object.prototype;
    const noresults = r && r.results && r.results.length === 0;
    return totallyEmptyObject || noresults;
}

export const useSearch = (keywords, body) => {
    async function fetchCards(){
        const res = await fetch(`${DOMAIN}/search/all?keywords=${keywords}`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body)
        })
        if (!res.ok) throw Error(res.statusText);
        return res.json();
    }

    return useQuery(['search','all',keywords,body], fetchCards, {enabled: keywords !== null});
}

export const useGoldinOnlySearch = (runGoldinOnly) => {
   async function fetchGoldinCards(){
    const res = await fetch(`${DOMAIN}/search/gn`)
    if (!res.ok) throw Error(res.statusText);
    return res.json();
   }
   return useQuery(['search','goldin'],fetchGoldinCards, {enabled: runGoldinOnly})
}

export const useHaOnlySearch = (runHaOnly) => {
    async function fetchGoldinCards(){
        const res = await fetch(`${DOMAIN}/search/ha`)
        if (!res.ok) throw Error(res.statusText);
        return res.json();
       }
       return useQuery(['search','ha'],fetchGoldinCards,{enabled: runHaOnly})
}

export const usePremiersOnlySearch = (runPremiers) => {
    async function fetchPremiers(){
        const res = await fetch(`https://pvpiwtdvcm.us-east-1.awsapprunner.com/search/premiers`)
        if (!res.ok) throw Error(res.statusText);
        return res.json();
    }
    return useQuery(['search','premiers'],fetchPremiers,{enabled: runPremiers})
}
export const useNewslettersOnlySearch = (runNewsLetter) => {
    async function fetchNewsletter(){
        const res = await fetch(`${DOMAIN}/search/newsletter`)
        if (!res.ok) throw Error(res.statusText);
        return res.json();
    }
    return useQuery(['search','newsletter'],fetchNewsletter,{enabled: runNewsLetter})
}
export function getTransformedResults(initialResults,keywords) {
  return isEmptyResultsObject(initialResults) ? 
         {
            ebRC: 0,
            metrics: null,
            count: 0,
            data: [],
            query: decodeURIComponent(keywords)
        } : {
            ebRC: initialResults.ebRc,
            metrics: initialResults.metrics,
            count: initialResults.results.length ? initialResults.results.length : 0,
            data: initialResults.results,
            query: decodeURIComponent(keywords)
        };
    }

